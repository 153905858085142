import React from "react";
import { Typography } from "@material-ui/core";
import Lottie from "react-lottie";

interface TileProps {
  animation: any;
  classes: string;
  content: string;
}

export const Tile: React.FC<any> = (props: TileProps) => {
  const animation = props.animation;
  const classes = props.classes;
  const content = props.content;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <React.Fragment>
      <Lottie options={defaultOptions} height={400} width={400} />
      <Typography className={classes}>
        <strong>{content}</strong>
      </Typography>
    </React.Fragment>
  );
};
