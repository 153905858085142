import React from "react";
import "./App.css";
import Main from "./container/Main"
import { AnimationTile } from "./components/animation_tile";
import { Introduction } from "./components/directorIntroduction";
import { MarketPlace } from "./components/marketPlace";

export const App: React.FC<any> = () => {
  return (
    <>
      <Main />
      <AnimationTile></AnimationTile>
      <Introduction />
      <MarketPlace />
    </>
  );
};

export default App;
