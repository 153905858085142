import React, { useState, useEffect } from "react";
import { Box, CssBaseline } from "@material-ui/core";
import { useStyles } from "./style";
import { gridData } from "./data/data";
import { Tile } from "./tile";

export const AnimationTile = (): any => {
  const [content, setContent] = useState(gridData[0]);

  const changeContent = () => {
    let index = content.id;
    console.log(index);
    if (index < gridData.length - 1) {
      setContent(gridData[++index]);
    } else {
      setContent(gridData[0]);
    }
  };
  useEffect(() => {
    const id = setInterval(changeContent, 5000);

    return function cleanup() {
      clearInterval(id);
    };
  });

  const classes = useStyles();
  return (
    <React.Fragment>
      <CssBaseline>
        <Box className={classes.container}>
          <Box className={classes.container__grid}>
            <Box className={classes.gridItem1}>
              <Tile
                animation={content.animationTileLeft}
                content={content.quote1}
                classes={classes.quotes__heading1}
              />
            </Box>

            <Box className={classes.gridItem2}>
              <Tile
                animation={content.animationTileRight}
                content={content.quote2}
                classes={classes.quotes__heading2}
              />
            </Box>
          </Box>
        </Box>
      </CssBaseline>
    </React.Fragment>
  );
};
