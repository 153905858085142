import { useStyles } from "./style";
import React from "react";
import { Box } from "@material-ui/core";
import image from "./asset/ceo.jpg";
import { introduction } from "./data";

export const Introduction: React.FC<any> = () => {
  const classes = useStyles();
  const getDegress = () => {
    return (
      <Box className={classes.grid__leftTile__item2}>
        {introduction.degrees.map((degree, index) => {
          return (
            <p className={classes.grid__leftTile__item2Content} key={index}>
              <strong>{degree} </strong>
            </p>
          );
        })}
      </Box>
    );
  };

  return (
    <Box className={classes.grid}>
      <Box className={classes.grid__leftTile}>
        <Box className={classes.grid__leftTile__item1}>
          <img className={classes.grid__leftTile__item1Image} src={image} alt={image}/>
        </Box>
        <Box>{getDegress}</Box>
      </Box>
      <Box className={classes.grid__rightTile}>
        <Box className={classes.grid__rightTile__item1}>
          <p>{introduction.about}</p>
        </Box>
      </Box>
    </Box>
  );
};
