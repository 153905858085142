export const introduction = {
  about:
    "Sundar Pichai is the current Chief Executive Officer (CEO) of the search engine Google. He is an India born world-class figure. The total salary of Sundar Pichai was around US$1,881,066 in 2018. Let us read this article to know more information about the Sundar Pichai., Sundar Pichai convinced the co-founders of Google, Sergey Brin, and Larry Page to launch Google’s browser. Sundar played an important role in the final launching of Google Chrome, in 2008. Eventually, Chrome became the No. 1 browser in the world, surpassing competitors such as Firefox and Internet Explorer. God help those who help themselves, and the launch of Google Chrome brings phenomenal success to the Sundar Pichai. Sundar Pichai became an internationally famous figure following the thrilling success of the Google Chrome. Finally after the gap of 11 years, since he joined Google; he was selected as the next CEO of Google on August 10, 2015. The Alphabet Inc. was formed in 2015 as a Google’s parent company. Sundar Pichai was awarded 273,328 shares of Google's holding company Alphabet in Feb.2016. Finally, on December 3, 2019, he became the CEO of Alphabet Inc. So, in conclusion, it can be said that Sundar Pichai is a great inventor and a complete corporate guy. As an Indian, we are proud on Sundar Pichai.",
  degrees: [
    "CEO of Google",
    "B.S. in Metallurgical Engineering at the Indian Institute of Technology in Kharagpur",
    "Master’s in material sciences and engineering",
    "Stanford University and MBA from the Wharton School of Business at the University of Pennsylvania",
  ],
};
