import meditation from "../lotties/meditation.json";
import stressed from "../lotties/stressed.json";
import peaceful from "../lotties/peaceful.json";
import thinking from "../lotties/thinking.json";
import disease from "../lotties/disease.json";
import mindfullness from "../lotties/mindfullness.json";

export const gridData = [
  {
    id: 0,
    animationTileLeft: thinking,
    animationTileRight: meditation,
    quote1: "Does your mind wander all the time ?? ",
    quote2: "Let's bring it to peace",
  },

  {
    id: 1,
    animationTileLeft: stressed,
    animationTileRight: peaceful,
    quote1: "Are you  stressed and lost??",
    quote2: "Let's enlighten the soul",
  },
  {
    id: 2,
    animationTileLeft: disease,
    animationTileRight: mindfullness,
    quote1: "Are you suffering from disease which is difficult to cure??",
    quote2: "Let's heal your body",
  },
  {
    id: 3,
    animationTileLeft: thinking,
    animationTileRight: meditation,
    quote1: "Does your mind wander all the time ?? ",
    quote2: "Let's bring it to peace",
  },

  {
    id: 4,
    animationTileLeft: stressed,
    animationTileRight: peaceful,
    quote1: "Are you  stressed and lost??",
    quote2: "Let's enlighten the soul",
  },
  {
    id: 5,
    animationTileLeft: disease,
    animationTileRight: mindfullness,
    quote1: "Are you suffering from disease which is difficult to cure??",
    quote2: "Let's heal your body",
  },
];
