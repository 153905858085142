export default interface IBook {
  id: string;
  title: string;

  image: string;
  description: string;
  price: string;
}
export const bookList: Array<IBook> = [
  {
    id: "1",
    title: "Glory Of Jain Tirthankaras",

    image:
      "https://cdn.exoticindia.com/details/books/glory_of_jaina_tirthankaras_idj749.jpg",
    description:
      "Details for Glory of Jaina Tirthankaras which belongs to the Hindu category in our Books collection",
    price: "Rs 500",
  },
  {
    id: "2",
    title: "Humanity's oldest religion of Non-Violence",
    image:
      "https://cdn.exoticindia.com/books/the_golden_book_of_jainism_humanitys_oldest_religion_idi940.jpg",
    description:
      "The Golden Book of Jainism.Jainism is a tradition which dates back thousands of years, which is unbelievably rich and profound and which has certain unmistakable signs of identity",
    price: "Rs 800",
  },
  {
    id: "3",
    title: "Jainism",

    image: "https://cdn.exoticindia.com/books/nac863.jpg",
    description:
      "The religious tradition of the Jainas, unique in many respects, presents a fascinating array of doctrinal and social structures that stem from the Anti-Vedic movements of ancient times.",
    price: "Rs 1000",
  },
];
