import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "30vw",
  },
  container__grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit,minmax(300px,1fr))",
    border: "1px solid black",
    width: "100%",

    position: "absolute",
  },
  gridItem1: {
    border: "1px solid black",
    height: "100%",
    backgroundImage: "radial-gradient( #5c5c3d 40%,  #1f1f14 60%)",
    background: "white",
    textAlign: "center",
    animation: `$animationForGridItem1 2000ms ease-in-out  both `,
  },
  gridItem2: {
    border: "1px solid black",
    height: "100%",
    textAlign: "center",
    backgroundImage: "radial-gradient( #99ddff 40%,  #33bbff 60%)",
    animation: `$animationForGridItem1 4000ms ease-in-out  both `,
    animationDelay: "2000ms",
  },
  gridItem1__image1: {
    marginTop: "10vw",
  },

  quotes__heading1: {
    fontFamily: "Arial, Helvetica, sans-serif",
    color: "white",
  },
  quotes__heading2: {
    fontFamily: "Arial, Helvetica, sans-serif",
    color: "black",
  },

  "@keyframes animationForGridItem1 ": {
    from: {
      opacity: "0",
    },
    to: {
      opacity: "1",
    },
  },

  "@keyframes animationForGridItem2 ": {
    from: {
      opacity: "0",
    },
    to: {
      opacity: "1",
    },
  },
});
