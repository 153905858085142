import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2vw",
    width: "25vw",
    height: "45vw",
  },

  cardMedia: {
    height: "20vw",
  },
  cardHeadings: {
    fontSize: "1.2vw",
    margin: "1vw",
  },
  cardContent: {
    height: "16vw",
    padding: "1vw",
  },
  cardActions: {
    padding: "1vw",
    [theme.breakpoints.between(0, 299)]: {
      flexFlow: "wrap-reverse",
    },
  },
  cardActions_button: {
    backgroundImage: "radial-gradient( #c4ff4d 5%,  #4d4d33 100%)",
    fontSize: "0.8vw",
    width: "10vw",
  },
  cardActions_heading: {
    alignSelf: "right",
    fontSize: "1.2vw",
    [theme.breakpoints.between(0, 699)]: {
      marginLeft: "2vw !important",
    },

    [theme.breakpoints.between(600, 1920)]: {
      marginLeft: "8vw !important",
    },
  },
}));
