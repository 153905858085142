import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  gridList: {
    height: "100vw",
    display: "grid",
    marginTop: "10vw",
    gridTemplateColumns: "repeat(auto-fit,minmax(25vw,1fr))",
    overflow: "hidden",
  },
  gridListTile: {
    padding: "2vw !important",
    height: "100% !important",
    width: "100% !important",
  },
}));
