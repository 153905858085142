import React from "react";
import { GridListTile, GridList } from "@material-ui/core";
import { bookList } from "./data";
import { useStyles } from "./style";
import { BookCard } from "./card";

export const MarketPlace: React.FC<any> = () => {
  const classes = useStyles();

  return (
    <GridList className={classes.gridList}>
      {bookList.map((tile) => {
        return (
          <GridListTile className={classes.gridListTile} key={tile.id}>
            <BookCard book={tile} />
          </GridListTile>
        );
      })}
    </GridList>
  );
};
