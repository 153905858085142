import React from "react";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
} from "@material-ui/core";
import { useStyles } from "./style";
import IBook from "../data";

interface Iprops {
  book: IBook;
}
export const BookCard: React.FC<any> = (props: Iprops) => {
  const classes = useStyles();
  const book = props.book;
  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt={book.description}
          image={book.image}
          title="Contemplative Reptile"
          className={classes.cardMedia}
        />
        <CardContent className={classes.cardContent}>
          <h1 className={classes.cardHeadings}>{book.title}</h1>

          <hr></hr>
          <p className={classes.cardHeadings}>{book.description}</p>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActions}>
        <Button className={classes.cardActions_button}>Want to buy</Button>
        <p className={classes.cardActions_heading}>
          <strong>{book.price}</strong>
        </p>
      </CardActions>
    </Card>
  );
};
