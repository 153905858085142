import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import newBook from '../assets/newbook.jpeg'
import bookstillnow from '../assets/bookstillnow.jpeg'
import firstpagenew from '../assets/firstpagenew.jpeg'
import newbookback from '../assets/newbookback.jpeg'
import secondpage from '../assets/secondpage.jpeg'
import { Grid, Paper } from '@material-ui/core';


const useStyles: any = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 */

 
// const tileData = [
//   {
//     img: newBook,
//     title: 'Image',
//     author: 'author',
//     cols: 2,
//   },
//   {
//     img: firstpagenew,
//     title: 'Image',
//     author: 'author',
//     cols: 2,
//   },
//   {
//     img: secondpage,
//     title: 'Image',
//     author: 'author',
//     cols: 2,
//   },
//   {
//     img: newbookback,
//     title: 'Image',
//     author: 'author',
//     cols: 2,
//   },
//   {
//     img: bookstillnow,
//     title: 'Image',
//     author: 'author',
//     cols: 2,
//   },
// ];
 
const ImageGridList: React.FC<any> = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs>
          <Paper className={classes.paper}>
              <img src={newBook} height={500} width={"auto"} alt="bhakatamar book"/>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className={classes.paper}>
            <img src={firstpagenew} height={500} width={"auto"} alt="bhakatamar book"/>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className={classes.paper}>
            <img src={secondpage} height={500} width={"auto"} alt="bhakatamar book"/>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs>
          <Paper className={classes.paper}>
            <img src={newbookback} height={500} width={"auto"} alt="bhakatamar book"/>
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Paper className={classes.paper}>
            <img src={bookstillnow} height={500} width={"auto"} alt="bhakatamar book"/>  
          </Paper>
        </Grid>
      </Grid>
    </div>

    // <div className={classes.root}>
    //   <GridList cellHeight={"auto"} className={classes.gridList} cols={3}>
    //     {tileData.map((tile) => (
    //       <GridListTile key={tile.img} cols={tile.cols || 1}>
    //         <img src={tile.img} alt={tile.title} style={{
    //             maxWidth: "50%",
    //         }} />
    //       </GridListTile>
    //     ))}
    //   </GridList>
    // </div>
  );
}

export default ImageGridList