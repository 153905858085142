import { Grid } from "@material-ui/core"
import React from "react"
import ImageTile from "./ImageTile"

const Main: React.FC<any> = () => (
    <div id="body">
        <section id="message">
            <h1>Bhaktamar Healing Center</h1>
            <h3>Launching soon....</h3>
        </section>
        <section id="book-launch">
            <h2>Book launch</h2>
            <ImageTile />
        </section>
        <section id="book-launch" style={{background: "#181818"}}>
            <h2>Best Moments from Bhaktamar Healing Center</h2>
            <Grid container spacing={3}>
                <Grid item xs={6} className="youtube">
                    <iframe title="iframe"width="560" height="315" 
                        src="https://www.youtube.com/embed/rFaPk2J9q3o" 
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                </Grid>
                <Grid item xs={6} className="youtube">
                    <iframe title="iframe"
                        width="560" 
                        height="315" src="https://www.youtube.com/embed/TC369ED8lSY"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                </Grid>
                <Grid item xs={6} className="youtube">
                    <iframe title="iframe"
                        width="560" 
                        height="315" src="https://www.youtube.com/embed/iZVlxBx26Gs"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                </Grid>
                <Grid item xs={6} className="youtube">
                    <iframe title="iframe"
                        width="560" 
                        height="315" src="https://www.youtube.com/embed/QZ1k8L7GCc0"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                </Grid>
                <Grid item xs={6} className="youtube">
                    <iframe title="iframe"
                        width="560" 
                        height="315" src="https://www.youtube.com/embed/VoSR7ZBelVA"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                </Grid>
                <Grid item xs={6} className="youtube">
                    <iframe title="iframe"
                        width="560" 
                        height="315" src="https://www.youtube.com/embed/MMuYrCBcvEc"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                </Grid>
                <Grid item xs={6} className="youtube">
                    <iframe title="iframe"
                        width="560" 
                        height="315" src="https://www.youtube.com/embed/nh5i8c5MckI"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                </Grid>
                <Grid item xs={6} className="youtube">
                    <iframe title="iframe"
                        width="560" 
                        height="315" src="https://www.youtube.com/embed/WcRS9MJXVDA"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                </Grid>ß
            </Grid>    
        </section>
    </div>
)

export default Main