import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  grid: {
    backgroundImage: "radial-gradient( #c4ff4d 5%,  #4d4d33 100%)",

    width: "100%",
    height: "40vw",
    display: "grid",
    gridTemplateColumns: "30vw 1fr",
    marginTop: "2vw",
    padding: "1vw",
  },
  grid__leftTile: {
    backgroundColor: "trasparent",
    display: "grid",
    gridTemplateRows: "30vw 1fr",
  },
  grid__leftTile__item1: {
    backgroundImage: "linear-gradient(#009999,#b3ffff)",
    borderRadius: "50% !important",
    padding: "1vw",
  },
  grid__leftTile__item1Image: {
    width: "100%",
    height: "100%",
    borderRadius: "50% !important",
  },
  grid__leftTile__item2: {
    backgroundColor: "trasparent",
    padding: "1vw",
    textAlign: "center",
  },
  grid__leftTile__item2Content: {
    fontSize: "1vw !important",
  },
  grid__rightTile: {
    backgroundColor: "trasparent",
    padding: "5vw",
  },
  grid__rightTile__item1: {
    fontSize: "1.2vw !important",
  },
});
